/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { handleResponse, handleError } from './response';
import config from '../config/config';

const oAuthEndpoint = config.BASE_URL + config.oAuthTokenEndpoint;
const oAuthGrantType = config.oAuthGrantType;
const oAuthClientId = config.oAuthClientId;
const resetPasswordUrl = config.apiServerDomain + config.resetPasswordEndpoint;

/** @param {string} resource */
/** @param {object} payload */
const signIn = (payload) => {
  return axios
    .post(`${oAuthEndpoint}`, {
      grant_type: oAuthGrantType,
      client_id: oAuthClientId,
      username: payload.username,
      password: payload.password,
    })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {object} payload : email */
const resetPassword = (payload) => {
  return axios
    .post(`${resetPasswordUrl}`, {
      email: payload.email,
    })
    .then(handleResponse)
    .catch(handleError);
};

export const authService = {
  signIn,
  resetPassword,
};
