/* eslint-disable no-unused-vars */
import { call, put, take, takeEvery, select, all, takeLatest } from 'redux-saga/effects';
import * as cutomiser from '../store/customizer/CustomizerSlice';
import * as common from '../store/common/CommonSlice';
import { Commonservice } from '../service/commonService';
import config from '../config/config';
import { getSliceData } from '../store/customizer/CustomizerSlice';
import { errorCode, sortNameArrayFilter } from '../helpers/commonHelpers';
// import { errorCode, findWorkspaceDetails,downloadMultiple, sortNameArrayFilter, sortArray } from '../helpers/commonHelpers';
// import { findObjects, makeObjectsExtensible, makeObjectsExtensibleDynamic, makeObjectsTraversing } from '../hooks/utilities';
// import { findId } from '../hooks/utilities';

const url = `http://${window?.location?.host}`;

function* getSearchPermission(action) {
  const { folderId } = action.payload;
  try {
    const response = yield Commonservice.checkPermissionSearch(folderId);
    if (response.data) {
      yield put(common.updateSearchPermission(response.data));
    }
  } catch (e) {
    yield put(common.updateCommonAlert({ state: true, errorCode: e?.response?.status }));
  }
}

function* getFavFolderItems(action) {
  const { portalId } = action.payload;
  yield put(cutomiser.updateServerLoader(true));
  try {
    const response = yield Commonservice.getFavFolders(portalId, 'folder');
    if (response?.data?.entries) {
      yield put(cutomiser.rebasedFavFolder(response.data.entries));
    }
    yield put(cutomiser.updateServerLoader(false));
  } catch (e) {
    yield put(cutomiser.updateServerLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Folder',
          body: 'fav_folder_error',
          defaultMessage: 'Unable to update fav folder.',
        },
      }),
    );
    console.log('infavFolder error', e);
  }
}

function* postFavFolders(action) {
  const { portalId, favFolders } = action.payload;
  yield put(cutomiser.updateServerLoader(true));
  try {
    const response = yield Commonservice.postFavFolder(portalId, favFolders, 'folder');
    if (response) {
      yield put(common.updateFavFolderGet({ portalId }));
    }
    yield put(cutomiser.updateServerLoader(false));
  } catch (e) {
    yield put(cutomiser.updateServerLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Folder',
          body: 'fav_folder_error',
          defaultMessage: 'Unable to update fav folder.',
        },
      }),
    );
    console.log(e);
  }
}

function* sendDeleteFavs(action) {
  const { portalId, favs, type } = action.payload;
  if (type === 'folder') {
    yield put(cutomiser.updateServerLoader(true));
  } else if (type === 'smart_folder') {
    yield put(cutomiser.updateHomesmartFolderLoader(true));
  }
  try {
    const response = yield Commonservice.deleteFavs(portalId, favs);
    if (response) {
      if (type === 'folder') {
        yield put(common.updateFavFolderGet({ portalId }));
      } else if (type === 'smart_folder') {
        yield put(common.updateFavSmartFolderGet({ portalId }));
      }
    }
    if (type === 'folder') {
      yield put(cutomiser.updateServerLoader(false));
    } else if (type === 'smart_folder') {
      yield put(cutomiser.updateHomesmartFolderLoader(false));
    }
  } catch (e) {
    if (type === 'folder') {
      yield put(cutomiser.updateServerLoader(false));
    } else if (type === 'smart_folder') {
      yield put(cutomiser.updateHomesmartFolderLoader(false));
    }
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'Folder',
          body: 'fav_folder_error',
          defaultMessage: 'Unable to update fav folder.',
        },
      }),
    );
    console.log('e', e);
  }
}

function* postFavSmartFolders(action) {
  const { portalId, favFolders, deleteItem } = action.payload;
  yield put(cutomiser.updateHomesmartFolderLoader(true));
  try {
    const response = yield Commonservice.postFavFolder(portalId, favFolders, 'smart_folder');
    if (response && deleteItem?.length === 0) {
      yield put(common.updateFavSmartFolderGet({ portalId }));
    }
    yield put(cutomiser.updateHomesmartFolderLoader(false));
  } catch (e) {
    yield put(cutomiser.updateHomesmartFolderLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'smartfolder_fav',
          body: 'fav_smart_folder_error',
          defaultMessage: 'Unable to update fav smart folder.',
        },
      }),
    );
    console.log(e);
  }
}

function* getFavSmartFolderItems(action) {
  const { portalId } = action.payload;
  let workpsacesSmartfolder = [];
  yield put(cutomiser.updateHomesmartFolderLoader(true));
  try {
    const responseWOrkspace = yield Commonservice.getPortalWorkspaces(portalId);
    workpsacesSmartfolder = responseWOrkspace?.data?.entries?.map((item) => {
      return {
        id: item.id,
        type: 'smart_folder',
        key: `workspace`,
        name: item.name,
        url: '/user/drive/smartfolder',
        data: `Files/Workspaces/${item.name}`,
        icon: 'Archive',
        pivot: `44/${item.id}`,
        Nodes: [],
      };
    });
    const dataSet = sortNameArrayFilter(workpsacesSmartfolder);
    yield put(cutomiser.updateSmartFolderNodes([...dataSet]));
    const response = yield Commonservice.getFavFolders(portalId, 'smart_folder');
    if (response?.data?.entries) {
      yield put(common.rebasedSmartFolder(response.data.entries));
    }
    yield put(cutomiser.updateHomesmartFolderLoader(false));
  } catch (e) {
    yield put(cutomiser.updateHomesmartFolderLoader(false));
    yield put(
      cutomiser.updateDriveError({
        status: true,
        message: {
          head: 'smartfolder_fav',
          body: 'fav_smart_folder_error',
          defaultMessage: 'Unable to update fav smart folder.',
        },
      }),
    );
  }
}

function* fetchItemPath(action) {
  const { itemId, type } = action.payload;
  try {
    const response = yield Commonservice.getFileFolderPathEndpoint(itemId, type);
    if (response) {
      yield put(common.updateItemPath(response));
    }
  } catch (e) {
    console.log('e>>>>', e);
  }
}

function* commonSaga() {
  yield takeEvery(common.updateGetSearchPerm, getSearchPermission);
  yield takeEvery(common.updateFavFolderGet, getFavFolderItems);
  yield takeEvery(common.updateSendFavFolders, postFavFolders);
  yield takeEvery(common.updateDeleteFavs, sendDeleteFavs);
  yield takeEvery(common.updateFavSmartFolderGet, getFavSmartFolderItems);
  yield takeEvery(common.updateSendFavSmartFolders, postFavSmartFolders);
  yield takeEvery(common.updateGetItemPath, fetchItemPath);
}
export default commonSaga;
