import { verify, sign } from 'jsonwebtoken';
import axios from './axios';
import { setTokenLocally, RemoveTokenLocally } from '../../helpers/commonHelpers';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const expireIn = localStorage.getItem('expireIn');
  const currentTime = Date.now() / 1000;
  return expireIn > currentTime;
};

const setSession = (accessToken, expiresIn, refreshToken) => {
  if (accessToken && expiresIn) {
    const payload = { accessToken, refreshToken, expireIn: expiresIn };
    setTokenLocally(payload);
    //   setTimeout(() => {
    //     localStorage.removeItem('accessToken');
    //     localStorage.removeItem('expireIn');
    // }, 10000);
  } else {
    RemoveTokenLocally();
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession, verify, sign };
