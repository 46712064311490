/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Button, Label, FormGroup, Container, Row, Col, Card, CardBody, Alert } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import AuthLogo from '../../layouts/logo/AuthLogo';
import { ReactComponent as LeftBg } from '../../assets/images/bg/login-bgleft.svg';
import { ReactComponent as RightBg } from '../../assets/images/bg/login-bg-right.svg';
import useAuth from '../../components/authGurad/useAuth';
import { FormattedMessage } from 'react-intl';
import * as Icon from 'react-feather';
import './Login.scss';

const Login = () => {
  const auth = useAuth();
  const [apiValidation, setapiValidation] = useState('');
  const [rememberme, setRememberme] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['auth']);
  const initialValues = {
    email: '',
    password: '',
    remember: false,
  };
  const [hidePassword, setHidePassword] = useState({
    showP: true,
    hideEverything: false,
  });

  useEffect(() => {
    initialValues.email = cookies.Email;
    initialValues.password = cookies.Password;
    setHidePassword({
      showP: true,
      hideEverything: false,
    });
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
  });

  return (
    <div className="loginBox">
      <LeftBg className="position-absolute left bottom-0" />
      <RightBg className="position-absolute end-0 top" />
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="12" className="loginContainer">
            <AuthLogo />
            <Card>
              <CardBody className="p-4 m-1">
                <h4 className="mb-0 fw-bold">
                  <FormattedMessage id="Log in" defaultMessage="Log in" description="login" />
                </h4>
                <small className="pb-4 d-block"></small>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(fields) => {
                    if (rememberme) {
                      const expires = new Date();
                      expires.setTime(expires.getTime() + 2 * 1000);
                      setCookie('Email', fields.email, 30, [{ path: '/', expires }]);
                      setCookie('Password', fields.password, 30, [{ path: '/', expires }]);
                    } else {
                      removeCookie(['Email']);
                      removeCookie(['Password']);
                    }
                    auth
                      .signInWithEmailAndPassword(fields.email, fields.password)
                      .then((response) => {
                        if (
                          response.error &&
                          response.error.response &&
                          response.error.response.data &&
                          response.error.response.data.error_description
                        ) {
                          setapiValidation(response.error.response.data.error_description);
                          //hiding the validation message
                          setTimeout(() => {
                            setapiValidation('');
                          }, 15000);
                        } else if (response.error && response.error.message) {
                          setapiValidation(response.error?.message);
                          //hiding the validation message
                          setTimeout(() => {
                            setapiValidation('');
                          }, 15000);
                        }
                      });
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <FormGroup>
                        <Label htmlFor="email">
                          <FormattedMessage id="Email" defaultMessage="Email" description="Email" />
                        </Label>
                        <Field
                          id="email"
                          name="email"
                          data-testid="email"
                          type="text"
                          className={`form-control${
                            errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="password" aria-labelledby="password">
                          <FormattedMessage
                            id="Password"
                            defaultMessage="Password"
                            description="Password"
                          />
                        </Label>
                        <div className="d-flex position-relative justify-content-center align-items-center">
                          <Field
                            id="password"
                            data-testid="password"
                            name="password"
                            type={hidePassword.showP ? 'password' : 'text'}
                            className={`form-control${
                              errors.password && touched.password ? ' is-invalid' : ''
                            }`}
                          />
                          {!hidePassword?.hideEverything && (
                            <>
                              {hidePassword.showP ? (
                                <Icon.Eye
                                  size={30}
                                  className="position-absolute end-0 pe-2"
                                  onClick={() =>
                                    setHidePassword({
                                      showP: false,
                                      hideEverything: false,
                                    })
                                  }
                                />
                              ) : (
                                <Icon.EyeOff
                                  size={30}
                                  className="position-absolute end-0 pe-2"
                                  onClick={() =>
                                    setHidePassword({
                                      showP: true,
                                      hideEverything: false,
                                    })
                                  }
                                />
                              )}
                            </>
                          )}
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center justify-content-between">
                        <div className="form-check d-flex padding-left-0 align-items-center ">
                          <input
                            name="remember"
                            type="checkbox"
                            className="remeberme"
                            onChange={() => {
                              setRememberme(!rememberme);
                              setHidePassword({
                                hideEverything: !hidePassword?.hideEverything,
                                showP: true,
                              });
                            }}
                          />
                          <Label check className="rememberLabel  ">
                            <FormattedMessage
                              id="Remember_me"
                              defaultMessage="Remember me"
                              description="Remember me"
                            />
                          </Label>
                        </div>
                        <div className="form-check d-flex padding-left-0 align-items-center ">
                          <Link className="ms-auto text-decoration-none" to="/auth/recoverpwd">
                            <small>
                              <FormattedMessage
                                id="Forgot Password?"
                                defaultMessage="Forgot password?"
                                description="Forgot password?"
                              />
                            </small>
                          </Link>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Button name="submit" type="submit" color="primary" block className="me-2">
                          <FormattedMessage id="Login" defaultMessage="Login" description="Login" />
                        </Button>
                      </FormGroup>
                      {apiValidation && <Alert color="danger">{apiValidation}</Alert>}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ul className="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};
export default Login;
