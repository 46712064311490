/* eslint-disable prefer-destructuring */
/* eslint-disable import/prefer-default-export */
//  import { handleResponse, handleError } from './response'; 
import axios from 'axios';
import config from '../config/config';
import AxiosInstance from '../components/jwt/axios';
import { decryptJwt, getModifiedDateString, detectNameCollision, generateNewName } from '../helpers/commonHelpers';

const resetPasswordUrl = `${config.apiServerDomain}/portals`;

/** @param {string} resource */
/** @param {object} payload */
const getProtal = () => {

  const portal = AxiosInstance.get(resetPasswordUrl).
    then((resp) => { return resp; })
  console.log(portal);

}

const getProfileInfo = async () => {
  const profileurl = `${config.apiServerDomain}/users/me`;
  const response = await AxiosInstance.get(profileurl);
  return response?.data;
}


const getPortalUserEndpoint = async () => {
  const portalUserEndpoint = `${config.apiServerDomain}/portals`;
  const response = await AxiosInstance.get(portalUserEndpoint);
  return response?.data;
}

const getPortalUsersEndpoint = async (data) => {
  const requestUrl = `${config.apiServerDomain}/portals/${data.portalId}/users_all?offset=${data.offset}&limit=${data.limit}`
  const response = await AxiosInstance.get(requestUrl)
  return response?.data
}
const getPortalPermissionEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/permissions`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getPortalAllowedClientsEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/allowedclients`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getAllWorkspacesListEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/workspaces?&filter=all&limit=500`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspace = async () => {
  const requestUrl = `${config.apiServerDomain}/workspaces`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspaceRootFolderChildren = async (workspaceId, type) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/root-folder/children?limit=2000&types=${type}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspacePermissionsEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/permissions`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspaceUsersEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/users`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspaceTrashChildrenEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/trash/children?limit=1000`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getUsersEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/users`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getPermissionsEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/permissions`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getAllFilesEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/files`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getUploadFileEndpoint = async (workspaceId, parentId, token) => {
  let requestUrl;
  if (parentId) {
    requestUrl = `${config.apiServerDomain}/folders/${parentId}files/content?access_token=${token}`;
  } else {
    requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}files/content?access_token=${token}`;
  }
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getUploadNewVersionEndpoint = async (fileId, token) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}/content?access_token=${token}`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getUploadChunkedInitEndpoint = async (workspaceId, parentId,file,fileSize = null) => {
  let requestUrl;
  let response
  const payload = {
    parent_id : workspaceId === parentId ? 0 : parentId,
    size : file.size ? file.size : fileSize
  }
  if (parentId === workspaceId) {
      requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/files/uploads`;
      response = await AxiosInstance.post(requestUrl,payload);
    } 
  else {
      requestUrl = `${config.apiServerDomain}/folders/${parentId}/files/uploads`;
      response = await AxiosInstance.post(requestUrl,payload);
  }
  return response?.data;
}



const getUploadChunksEndpoint = async (uploadId,file,offset) => {
  const requestUrl = `${config.apiServerDomain}/files/uploads/${uploadId}`;
  const formData = new FormData();
  formData.append('offset',offset);
  formData.append('file',file,`chunk${uploadId}-${offset}`)
  
  const options = { content: formData };
  const response = await AxiosInstance.post(requestUrl,formData,options);
  return response;
}


const uploadChunkedEndApi = async (uploadId,comment='',token,parentId,workspaceId,file,isDuplicate,fileId) => {
  let requestUrl = ''
  const formData = new FormData
  requestUrl = isDuplicate ? 
  `${config.apiServerDomain}/files/${fileId}/content?access_token=${token}`
   :
    parseInt(parentId,10) ===  workspaceId ?
  `${config.apiServerDomain}/workspaces/${workspaceId}/files/content?access_token=${token}` :  `${config.apiServerDomain}/folders/${parentId}/files/content?access_token=${token}`
   if(isDuplicate){
    formData.append('comment',comment)
    formData.append('upload_id',uploadId)
    formData.append('content_modified_at',getModifiedDateString(file))
   }
   else{
    formData.append('name',file.name)
    formData.append('comment',comment)
    formData.append('upload_id',uploadId)
    formData.append('content_modified_at',getModifiedDateString(file))
    formData.append("parent_id", parseInt(parentId,10) === workspaceId ? 0 : parentId)
   }

  const response = await axios.post(requestUrl,formData)
  return response
}

const getDownloadFileEndpoint = async (fileId, token) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}/content?access_token=${token}`;
  const response = await axios.post(requestUrl);
  return response?.data;
}

const restrictedUploadFile = async (fileId) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}`;
  const payload  = {shared_link:{access_type:"restricted"}}
  const response = await AxiosInstance.put(requestUrl,payload)
  return response
}
const getDownloadSharedLinkEndpoint = async (fileId) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}/content`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSharedResourceEndpoint = async (token) => {
  const requestUrl = `${config.apiServerDomain}/shared-links/${token}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFilePermissionsEndpoint = async (fileId) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}/permissions`;
  const response = await AxiosInstance.get(requestUrl);
  return response;
}

const getFileVersionsEndpoint = async (fileId) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}/versions`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFileVersionsUpdateEndpoint = async (fileversionId) => {
  const requestUrl = `${config.apiServerDomain}/files/version/${fileversionId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFileCopyEndpoint = async (fileId, content) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}/copy`;
  const response = await AxiosInstance.post(requestUrl, content);
  return response?.data;
}

const getFileTrashEndpoint = async (fileId) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}/trash`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getSharedLinkEndpoint = async (token) => {
  const requestUrl = `${config.apiServerDomain}/shared-links/${token}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSharedLinkUrlEndpoint = async (sharedLinkId) => {
  const requestUrl = `${config.apiServerDomain}/shared-links/${sharedLinkId}/url`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSharedLinkEmailNotificationEndpoint = async (sharedLinkId) => {
  const requestUrl = `${config.apiServerDomain}/shared-links/${sharedLinkId}/email-notifications`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getSharedLinkMultiEmailNotificationEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/shared-links/multi-email-notifications`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getFileFolderEmailOptionsEndpoint = async (entityType, id) => {
  const requestUrl = `${config.apiServerDomain}/${entityType}s/${id}/selective-sync'`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getCreateFolderEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/folders`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getFolderEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/folders`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFolderDetail = async (folderId) => {
  const requestUrl = `${config.apiServerDomain}/folders/${folderId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFileDetail = async (fileId) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFolderChildrenEndpoint = async (folderId) => {
  const requestUrl = `${config.apiServerDomain}/folders/${folderId}/children?limit=500&types=folder&node=${folderId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFolderAndFilesChildrenEndpoint = async (folderId) => {
  const requestUrl = `${config.apiServerDomain}/folders/${folderId}/children?limit=2000&types=file%2Cfolder`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFolderCopyEndpoint = async (folderId, content) => {
  const requestUrl = `${config.apiServerDomain}/folders/${folderId}/copy`;
  const response = await AxiosInstance.post(requestUrl, content);
  return response?.data;
}

const getFolderPermissionsEndpoint = async (folderId) => {
  const requestUrl = `${config.apiServerDomain}/folders/${folderId}/permissions`;
  const response = await AxiosInstance.get(requestUrl);
  return response;
}

const getFolderTrashEndpoint = async (folderId) => {
  const requestUrl = `${config.apiServerDomain}/folders/${folderId}/trash`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSmartFolderEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/smart-folders`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getCreateSmartFolderEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/smart-folders`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getSmartFolderChildrenEndpoint = async (smartFolderId) => {
  const requestUrl = `${config.apiServerDomain}/smart-folders/${smartFolderId}/children?types=smart_folder&node=${smartFolderId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSmartFolderPermissionsEndpoint = async (smartFolderId) => {
  const requestUrl = `${config.apiServerDomain}/smart-folders/${smartFolderId}/permissions`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSmartFolderTrashEndpoint = async (smartFolderId) => {
  const requestUrl = `${config.apiServerDomain}/smart-folders/${smartFolderId}/trash`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getPortalMetafieldEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/meta-fields?limit=1000`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getMetafieldEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/meta-fields`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getMetafieldValueEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/meta-fields-values`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getResourceMetafieldValueEndpoint = async (entityType, id) => {
  /* entityType should be one of file, folder or smart_folder. */
  const type = `${entityType}s`;
  const requestUrl = `${config.apiServerDomain}/${type}/${id}/meta-fields-values`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getMetafieldCriterionEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/meta-fields-criteria`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSmartFolderMetafieldCriteriaEndpoint = async (smartFolderId) => {
  const requestUrl = `${config.apiServerDomain}/smart-folders/${smartFolderId}/meta-fields-criteria`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getInviteUserUrl = async () => {
  const requestUrl = `${config.apiServerDomain}/invitations`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getInvitationsEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/invitations`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getAcceptInvitationEndpoint = async (token) => {
  const requestUrl = `${config.apiServerDomain}/invitations/${token}/accept`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getResendInvitationEndpoint = async (invitationId) => {
  const requestUrl = `${config.apiServerDomain}/invitations/${invitationId}/resend`;
  const response = await AxiosInstance.post(requestUrl);
  return response;
}

const getConfirmEmailEndpoint = async (token) => {
  const requestUrl = `${config.apiServerDomain}/sign-up/${token}/confirm-email`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getFolderInboundEmail = async (folderId) => {
  const requestUrl = `${config.apiServerDomain}/folders/${folderId}/inbound-email`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getWorkspaceInboundEmail = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/inbound-email`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getPortalGroupsEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/groups`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspaceGroupsEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/groups?limit=500`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getGroupsEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/groups`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getCreateMembershipEndpoint = async (type, workspaceId, portalId) => {
  const requestUrl = (type === 'portal') ? `${config.apiServerDomain}/portals/${portalId}/group-memberships` : `${config.apiServerDomain}/workspaces/${workspaceId}/group-memberships`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getMembershipsEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/group-memberships`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getMembershipsForGroup = async (groupId) => {
  const requestUrl = `${config.apiServerDomain}/groups/${groupId}/group-memberships`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getMembershipsForUser = async (userId, portalId) => {
  const requestUrl = `${config.apiServerDomain}/users/${userId}/portals/${portalId}/group-memberships`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getGroupsForUser = async (userId, portalId) => {
  const requestUrl = `${config.apiServerDomain}/users/${userId}/portals/${portalId}/groups`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspaceGroupMembershipsForUser = async (userId, workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/users/${userId}/workspaces/${workspaceId}/group-memberships`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getWorkspaceGroupsForUser = async (userId, workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/users/${userId}/workspaces/${workspaceId}/groups`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getEventsEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/events`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getSearchEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/search`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getResetPasswordEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/users/reset-password`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getHistoryEndpoint = async (query,portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/historyevents?${query}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFDVEntriesEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/fdv`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFDVLicenseEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/fdv/${portalId}/license`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFDVExportEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/fdv/${portalId}/export?file=Export.xls`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getCopyWorkspaceEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/copy`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFileFolderPathEndpoint = async (itemId, type) => {
  let requestUrl;
  if (type === 'file') {
    requestUrl = `${config.apiServerDomain}/files/${itemId}/path`;
  } else {
    requestUrl = `${config.apiServerDomain}/folders/${itemId}/path`;
  }
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getUpdateWorkspaceEndpoint = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getZipDownloadInitiateEndpoint = async () => {
  const requestUrl = `${config.apiServerDomain}/files/zip`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}

const getZipDownloadEndpoint = async (zip, token) => {
  const requestUrl = `${config.apiServerDomain}${zip}&access_token=${token}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getFileInfoEndpoint = async (itemId) => {
  const requestUrl = `${config.apiServerDomain}/files/${itemId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getDomainAndPortalUrl = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const getCreateNewWorkspaceEndpoint = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/workspaces`;
  const response = await AxiosInstance.post(requestUrl);
  return response?.data;
}
const loadWeatherInfo = async (SelectedCity,lang) => {
  const requestUrl = `${config.WEATHER_ORDINARY}?q=${SelectedCity?.name}&lang=${lang}&APPID=${config.WEATHER_KEY}`
  const response = await axios.get(requestUrl).then((res) => {
    return res
  })
  return response.data
}
const loadWeatherMap = async () => {
  const requestUrl = `${config.WEATHER_MAP_API_URL}?q=Stockholm&APPID=${config.WEATHER_KEY}`
  const response = await axios.get(requestUrl).then((res) => {
    return res
  })
  return response?.data
}


const getAllWorkspacesListEndpointFolder = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/workspaces?limit=500&types=folder&node=fileRoot`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}

const putSingleAllowedClient = async (portalId, userId, content) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/allowedclients/${userId}`
  const response = await AxiosInstance.put(requestUrl, content);
  return response?.data;
}

const deletePortalMember = async (userid,portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/users/${userid}`
  const response = await AxiosInstance.delete(requestUrl);
  return response
}
const updateProfilePhoto = async (file) => {
  const requestUrl = `${config.NODE_API_URL}/files/profileimage`;
  const response = await AxiosInstance.post(requestUrl, file);
  return response?.data;
};
const addWorkSpace = async (data) => {
  const { portal, sentParams } = data
  const requestUrl = `${config.apiServerDomain}/portals/${portal}/workspaces`;
  const response = await AxiosInstance.post(requestUrl, sentParams);
  return response?.data
};

const getWorkspaceRootSmartFolderChildren = async (workspaceId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/root-folder/children?limit=500&types=smart_folder`;
  const response = await AxiosInstance.get(requestUrl);
  return response?.data;
}


const fetchLabel = async (data) => {
  const labelUrl = `${config.apiServerDomain}/portals/${data.id}/meta-fields?offset=${data.offset}&limit=${data.limit}`
  const response = await AxiosInstance.get(labelUrl)
  return response?.data
};

const getAllPortalUsers = async (portalId) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/users_all`
  const response = await AxiosInstance.get(requestUrl)
  return response?.data
};
const deleteInvitation = async (id) => {
  const content = {
    id
  }
  const requestUrl = `${config.apiServerDomain}/invitations/${id}`
  const response = await AxiosInstance.delete(requestUrl, content);
  return response
}
const putWorkSpaceCopy = async (selectWorkspace, payload) => {
  const workUrl = `${config.apiServerDomain}/workspaces/${selectWorkspace}/copy`
  const response = await AxiosInstance.post(workUrl, payload)
  return response
};

const deleteWorkSpace = async (data) => {
  const URL = `${config.apiServerDomain}/workspaces/${data.workspaceId}?filter=accessible`
  const response = await AxiosInstance.delete(URL)
  return response
}
const putNameWorkSpace = async (data) => {
  const { fields, workspaceId } = data
  const URL = `${config.apiServerDomain}/workspaces/${workspaceId}`
  const response = await AxiosInstance.put(URL, fields);
  return response
}
const sendDefaultSync = async (data) => {
  const { workspaceId, fields } = data
  const URL = `${config.apiServerDomain}/workspaces/${workspaceId}`
  const response = await AxiosInstance.put(URL, fields)
  return response
}

const changeWorkspacePermission = async (permissionId, content) => {
  const requestUrl = `${config.apiServerDomain}/permissions/${permissionId}`
  const response = await AxiosInstance.put(requestUrl, content)
  return response
}

const postLabel = async (portalId, data) => {
  const requestUrl = `${config.apiServerDomain}/portals/${portalId}/meta-fields`
  const response = await AxiosInstance.post(requestUrl, data)
  return response
}

const sendUserWorkSpace = async (action) => {
  const { id, payload } = action
  const URL = `${config.apiServerDomain}/workspaces/${id}/permissions`
  const response = await AxiosInstance.post(URL, payload)
  return response
}
const deleteUserInWorkspace = async (workspaceId,userId) => {
  const requestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/users/${userId}
  `
  const response = await AxiosInstance.delete(requestUrl)
  return response
}

const putLabel = async (labelId, content) => {
  const requestUrl = `${config.apiServerDomain}/meta-fields/${labelId}`
  const response = await AxiosInstance.put(requestUrl, content)
  return response

}

const deleteLabel = async (labelId, content) => {
  const requestUrl = `${config.apiServerDomain}/meta-fields/${labelId}`
  const response = await AxiosInstance.delete(requestUrl, content)
  return response
}

const deleteGroup = async (sentPayload) => {
  const URL = `${config.apiServerDomain}/groups/${sentPayload.id}`
  const response = await AxiosInstance.delete(URL, sentPayload.id);
  return response
}

const sentAddGroup = async (action) => {
  const { payload, limit, Id } = action
  const URL = `${config.apiServerDomain}/workspaces/${Id}/groups?limit=${limit}`
  const response = await AxiosInstance.post(URL, payload)
  return response
}

const fetchGroupMembers = async (action) => {
  const { id, limit } = action;
  const URL = `${config.apiServerDomain}/groups/${id}/group-memberships?limit=${limit}`
  const response = await AxiosInstance.get(URL)
  return response
}

const getWorkspaceMembers = async (action) => {
  const { id, page, start, limit } = action
  const URL = `${config.apiServerDomain}/workspaces/${id}/users?&page=${page}&start=${start}&limit=${limit}`
  const response = await AxiosInstance.get(URL)
  return response
}
const postDeleteGroupMember = async (action) => {
  const { id } = action
  const URL = `${config.apiServerDomain}/group-memberships/${id}`
  const response = await AxiosInstance.delete(URL, action)
  return response
}

const sentAddGrpMember = async (action) => {
  const URL = `${config.apiServerDomain}/group-memberships`
  const response = await AxiosInstance.post(URL, action)
  return response
}
const putAllowedClientsMultipleUsers = async (portalId, content) => {
  const RequestUrl = `${config.apiServerDomain}/portals/${portalId}/allowedclient/updateMultiple`
  const response = await AxiosInstance.put(RequestUrl, content)
  return response
}
const updateDragnDropFile = async (data, fileStore) => {
  const tokenEncrypted = await localStorage.getItem('accessToken');
  const token = decryptJwt(tokenEncrypted);
  const id = data.id;
  let requestUrl = '';
  const uploadFinishForm = document.createElement("form");
  const finishFormData = new FormData(uploadFinishForm);
  const contentModified = getModifiedDateString(data.file);
  let response;
  const collision = detectNameCollision(data.fileType, data.name, fileStore.entries);
  if (collision) {
    finishFormData.append("file", data.file);
    const record = fileStore.entries.filter((item) => item.name === data.name);
    finishFormData.append("content_modified_at", contentModified);
    requestUrl = `${config.apiServerDomain}/files/${record[0].id}/content?access_token=${token.accessToken}`;
    response = await axios.post(requestUrl, finishFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    }).then((res) => {
      return res
    });
  } else {
    if (data.key === 'workspace') {
      finishFormData.append("name", data.name);
      finishFormData.append("parent_id", 0);
      finishFormData.append("content_modified_at", contentModified);
      finishFormData.append("file", data.file);
      finishFormData.append("comment",data.comment)
      requestUrl = `${config.apiServerDomain}/workspaces/${id}/files/content?access_token=${token.accessToken}`;
    } else {
      finishFormData.append("name", data.name);
      finishFormData.append("parent_id", id);
      finishFormData.append("content_modified_at", contentModified);
      finishFormData.append("file", data.file);
      requestUrl = `${config.apiServerDomain}/folders/${id}/files/content?access_token=${token.accessToken}`;
    }
    response = await axios.post(requestUrl, finishFormData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      return res
    });
    //linking files
    if (response?.data) {
      const fileId = response?.data.id;
      const url = `${config.apiServerDomain}/files/${fileId}`;
      const dataPayload = { "shared_link": { "access_type": "restricted" } }
      const finaleResponse = await AxiosInstance.put(url, dataPayload);
      return finaleResponse?.data;
    }
  }
  return response?.data;
};

const postInvite = async (content) => {
  const RequestUrl = `${config.apiServerDomain}/invitations`
  const response = await AxiosInstance.post(RequestUrl, content)
  return response
}
const renameGroup = async (fields, id) => {
  const URL = `${config.apiServerDomain}/groups/${id}`
  const response = await AxiosInstance.put(URL, fields)
  return response
}

const getSearchFile = async (action) => {
  const { types, search, id, page, start, limit } = action
  const URL = `${config.apiServerDomain}/search?&types=${types}&search=${search}&portal=${id}&page=${page}&start=${start}&limit=${limit}`
  const response = await AxiosInstance.get(URL)
  return response
}

const restoreFromRecyclebin = async (id, content,type) => {
  let restoreType 
  switch(type){
    case 'file' : restoreType = 'files';break;
    case 'folder' : restoreType = 'folders';break;
    case 'smart_folder' : restoreType = 'smart-folders';break;
    default : restoreType =  null
    
  }
  const requestUrl = `${config.apiServerDomain}/${restoreType}/${id}`
  const response = await AxiosInstance.post(requestUrl, content)
  return response
}
const clearRecyclebin = async (portalId) => {
  const RequestUrl = `${config.apiServerDomain}/portals/${portalId}/recyclebin`
  const response = await AxiosInstance.delete(RequestUrl)
  return response
}

const createSmartFolderName = async (workspaceId, content) => {
  const RequestUrl = `${config.apiServerDomain}/workspaces/${workspaceId}/smart-folders`
  const response = await AxiosInstance.post(RequestUrl, content)
  return response
}

const createSmartFolderMetaData = async (fileId, contentitem) => {
    const RequestUrl = `${config.apiServerDomain}/smart-folders/${fileId}/meta-fields-criteria`
    const response = await AxiosInstance.post(RequestUrl, contentitem)
    return response
}

const getSmartFolderMetaData = async (fileId) => {
  const RequestUrl = `${config.apiServerDomain}/smart-folders/${fileId}/meta-fields-criteria`
  const response = await AxiosInstance.get(RequestUrl)
  return response
}

const deleteRecycleBinData = async (fileType, id) => {
  const URL = `${config.apiServerDomain}/${fileType}/${id}/trash`
  const response = await AxiosInstance.delete(URL, id)
  return response
}

const deleteRecycleBinItems = async (workspaceId) => {
  const URl = `${config.apiServerDomain}/workspaces/${workspaceId}/trash/deleteChildren`
  const response = await AxiosInstance.delete(URl)
  return response
}


const configureSmartFolder = async (content1, folderId, content2) => {
  const RequestURL1 = `${config.apiServerDomain}/smart-folders/${folderId}`
  const response1 = await AxiosInstance.put(RequestURL1, content1)
  content2.forEach(async (item) => {
    const contentfinal = {
      condition_type: item?.condition_type,
      value: item?.value
    }
    const RequestURL2 = `${config.apiServerDomain}/meta-fields-criteria/${item?.metaId}`
     AxiosInstance.put(RequestURL2, contentfinal)
  })
  const finalResponse = {
    response1,
  }
  return finalResponse
}

const configureNameSmartFolder = async (content,folderId) => {
  const requestUrl = `${config.apiServerDomain}/smart-folders/${folderId}`
  const response = await AxiosInstance.put(requestUrl,content)
  return response
}

const sentEmail = async (action) => {
  const { key, menuId } = action
  const URL = `${config.apiServerDomain}/${key}/${menuId}/inbound-email`
  const response = await AxiosInstance.get(URL)
  return response
}
const deleteSmartFolderItem = async (fileId) => {
  const content = { id: fileId?.id }
  const URL = `${config.apiServerDomain}/smart-folders/${fileId?.id}`
  const response = await AxiosInstance.delete(URL, content)
  return response
}
const updateLinkAccess = async (fileId, data) => {
  const requestUrl = `${config.apiServerDomain}/files/${fileId}`;
  const response = await AxiosInstance.put(requestUrl, data);
  return response?.data;
}

const sentFolderDownload = async(action) => {
  const URl = `${config.apiServerDomain}/files/zip`
  const response  = await AxiosInstance.post(URl,action)
  return `${config.apiServerDomain}${response?.data?.Location}`
}

const createNewFileFolder = async (action) => {
  const {workspaceId,name,parent} = action
  const payload = {name,parent}
  const  URl = `${config.apiServerDomain}/workspaces/${workspaceId}/folders`
  const response = await AxiosInstance.post(URl,payload)
  return response
}

const sentGetVersions = async(action) => {
  const url = `${config.apiServerDomain}/files/${action}/versions`
  const response = await AxiosInstance.get(url)
  return response
}

const postFileComment = async(action) => {
  const {fields,selected} = action
  const url = `${config.apiServerDomain}/files/version/${selected}`
  const response = await AxiosInstance.put(url,fields)
  return response
}

const sentFilePreview = async(action) => {
  const url = `${config.apiServerDomain}/files/${action.id}/content?access_token=${action.token}`
  const response = await axios({url,method:"options"})
  return `${config.apiServerDomain}${response?.data?.url}`
}

const sentViewId = async(action) => {
const response = await axios.post(`https://api.accusoft.com/prizmdoc/ViewingSession`,action,{headers:{
    "acs-api-key":"P4022014082320",
    'Content-Type': 'application/json'

  }})
  return response
}

const putFileCut = async(fileId, content) => {
  const url = `${config.apiServerDomain}/files/${fileId}`
  const response = await AxiosInstance.put(url,content)
  return response
}

const putFolderCut = async(folderId, content) => {
  const url = `${config.apiServerDomain}/folders/${folderId}`
  const response = await AxiosInstance.put(url,content)
  return response
}

const drivePasteOperation = async (data, fileStore, editOperation, menuDetails) => {
  
  const collision = detectNameCollision(data.entity_type, data.name, fileStore.entries);
  let resp ='';
  if (collision) {
    const filename = generateNewName(data.name, data.entity_type, fileStore?.entries);
    const key = menuDetails?.key;
    const parentId = key === 'workspace'? 0: menuDetails?.menuId;
    const content = {name: filename, parent : {id: parentId}};
    if(editOperation?.operation === 'copy') {
      if(data.entity_type === 'file') {
        resp = getFileCopyEndpoint(data.id, content);
      } else if(data.entity_type === 'folder') {
        resp = getFolderCopyEndpoint(data.id, content);
      }
     
    } else if(editOperation?.operation === 'cut'){
      if(data.entity_type === 'file') {
        resp = putFileCut(data.id, content);
      } else {
        resp = putFolderCut(data.id, content);
      }
      
    }
  } else {
    const key = menuDetails?.key;
    const parentId = key === 'workspace'? 0: menuDetails?.menuId;
    const content = {name: data.name, parent : {id: parentId}};
    if(editOperation?.operation === 'copy') {
      if(data.entity_type === 'file') {
        resp = getFileCopyEndpoint(data.id, content);
      } else if(data.entity_type === 'folder') {
        resp = getFolderCopyEndpoint(data.id, content);
      }
     
    } else if(editOperation?.operation === 'cut'){
      if(data.entity_type === 'file') {
        resp = putFileCut(data.id, content);
      } else {
        resp = putFolderCut(data.id, content);
      }
      
    }
  }

  return resp;
}

const postFolderPermission  = async(payload,id) => {
const url = `${config.apiServerDomain}/folders/${id}/permissions`;
const response = await AxiosInstance.post(url,payload)
return response
}

const postFilePermission = async(payload,id) => {
  console.log("payload in file permission")
  const url = `${config.apiServerDomain}/files/${id}/permissions`
  const response = await AxiosInstance.post(url,payload)
  return response
}

const putUserPermission = async(action) => {
  const url  = `${config.apiServerDomain}/permissions/${action.id}`
  const response = await AxiosInstance.put(url,action.body)
  return response
}

const deleteMetafromSmartFolder = async (metaId, content) => {
  const RequestURL = `${config.apiServerDomain}/meta-fields-criteria/${metaId}`
  const response = await AxiosInstance.delete(RequestURL, content)
  return response
}

const postDriveLabelDataUrl = async (selectedID,content,type) => {
  const RequestURL = `${config.apiServerDomain}/${type}s/${selectedID}/meta-fields-values`
  const response =  await AxiosInstance.post(RequestURL,content)
  return response
}

const putDriveLabelDataUrl = async(metaFieldId,content) => {
  const RequestURL = `${config.apiServerDomain}/meta-fields-values/${metaFieldId}`
  const response = await AxiosInstance.put(RequestURL,content)
  return response
}

const deleteDriveLabelDataUrl = async (deleteId,content) => {
  const RequestURL = `${config.apiServerDomain}/meta-fields-values/${deleteId}`
  const response = await AxiosInstance.delete(RequestURL,content)
  return response
}

const postDriveSendLink = async (content) => {
  const RequestURL = `${config.apiServerDomain}/shared-links/multi-email-notifications`
  const response = await AxiosInstance.post(RequestURL,content)
  return response
}

const postFileSubscription = async (postData,driveSelectedItem) => {
  let RequestURL = '';
  const fileType = driveSelectedItem?.original.entity_type;
  const id = driveSelectedItem?.original.id;
  if(fileType === 'file') {
    RequestURL = `${config.apiServerDomain}/files/${id}/email-options`;
  } else {
    RequestURL = `${config.apiServerDomain}/folders/${id}/email-options`;
  }
  const response = await AxiosInstance.put(RequestURL,postData)
  return response;
}

const postFileSync = async (postData, driveSelectedItem) => {
  let RequestURL = '';
  let response = '';
  const fileType = driveSelectedItem?.original.entity_type;
  const id = driveSelectedItem?.original.id;
  if(fileType === 'file') {
    RequestURL = `${config.apiServerDomain}/files/${id}/selective-sync`;
  } else {
    RequestURL = `${config.apiServerDomain}/folders/${id}/selective-sync`;
  }
  if(postData.delete) {
    response = await AxiosInstance.delete(RequestURL);
  } else {
    response = await AxiosInstance.put(RequestURL);
  }
  
  return response;
}
const postFileLock = async (postData,driveSelectedItem) => {
  let RequestURL = '';
  const id = driveSelectedItem?.original.id;
  RequestURL = `${config.apiServerDomain}/files/${id}`;
  const response = await AxiosInstance.put(RequestURL,postData)
  return response;
}

const deleteFileFolderFromDrive = async (type,itemId,content) => {
  const RequestURL = `${config.apiServerDomain}/${type}s/${itemId}`
  const response = await AxiosInstance.delete(RequestURL,content)
  return response
}

const renameFileFolderInDrive = async (type,itemId,content) => {
  const RequestURL = `${config.apiServerDomain}/${type}s/${itemId}`
  const response = await AxiosInstance.put(RequestURL,content)
  return response
}

const deleteSmartFolderUrl = async (id) => {
const content = {id}
const RequestURL = `${config.apiServerDomain}/smart-folders/${id}`
const response = await AxiosInstance.delete(RequestURL,content)
return response
}

const defaultMemberinWorkspace = async (id,content) => {
 const RequestUrl =  `${config.apiServerDomain}/workspaces/${id}/permissions`
  const response = await AxiosInstance.post(RequestUrl,content)
  return response
}

const updateDropFileProgress = async (data, fileStore,onUploadProgress) => {
    const tokenEncrypted =  localStorage.getItem('accessToken');
    const token = decryptJwt(tokenEncrypted);
    const id = data?.id;
    let requestUrl = '';
    const uploadFinishForm = document.createElement("form");
    const finishFormData = new FormData(uploadFinishForm);
    const contentModified = getModifiedDateString(data.file);
    let response;
    let collision = false

    if(data?.isNewParent === 0 ){
      collision = detectNameCollision(data.fileType, data.name, fileStore.entries);
    }

    if (collision) {
      finishFormData.append("file", data.file);
      finishFormData.append("comment",data.comment)
      const record = fileStore.entries.filter((item) => item.name === data.name);
      finishFormData.append("content_modified_at", contentModified);
      requestUrl = `${config.apiServerDomain}/files/${record[0].id}/content?access_token=${token.accessToken}`;
      response = await axios.post(requestUrl, finishFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },onUploadProgress
      })
    
    } else {
      if (data.key === 'workspace') {
        
        finishFormData.append("name", data.name);
        finishFormData.append("parent_id", 0);
        finishFormData.append("content_modified_at", contentModified);
        finishFormData.append("file", data.file);
        finishFormData.append("comment",data.comment)
        requestUrl = `${config.apiServerDomain}/workspaces/${id}/files/content?access_token=${token.accessToken}`;
      } else {
        finishFormData.append("name", data.name);
        finishFormData.append("parent_id", id);
        finishFormData.append("content_modified_at", contentModified);
        finishFormData.append("file", data.file);
        finishFormData.append("comment",data.comment);
        requestUrl = `${config.apiServerDomain}/folders/${id}/files/content?access_token=${token.accessToken}`;
      }
      response = await axios.post(requestUrl, finishFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },onUploadProgress
      })
  
      //linking files
      if (response?.data) {
        const fileId = response?.data.id;
        const url = `${config.apiServerDomain}/files/${fileId}`;
        const dataPayload = { "shared_link": { "access_type": "restricted" } }
        const finaleResponse = await AxiosInstance.put(url, dataPayload);
        return finaleResponse;
      }
      
    }
    return response;
};


const downloadFile = async(id,token) => {
const url = `${config.apiServerDomain}/files/${id}/content?access_token=${token}&open_style=attachment`
const response = await axios.get(url)
return response
}

const getFolder = async(id) => {
  const url = `${config.apiServerDomain}/folders/${id}`
  const response = await AxiosInstance.get(url)
  return response
} 

const getLoggedUserPortalPermission = async(portalId) => {
  const url = `${config.apiServerDomain}/permissions/portal/${portalId}`
const response = await AxiosInstance.get(url)
return response?.data
}

const getLoggedUserWorkspacePermission = async (workspaceId) => {
  const url = `${config.apiServerDomain}/permissions/workspace/${workspaceId}`
  const response = await AxiosInstance.get(url)
  return response?.data
}

const highestFolderFilePrem = async(folderId) => {
const url = `${config.apiServerDomain}/folders/${folderId}/highest-permission`
const response = await AxiosInstance.get(url)
return response
}

const highestFileFolderPremContent = async(folderId) => {
  const url = `${config.apiServerDomain}/folders/${folderId}/has-edit-permission`
  const response = await AxiosInstance.get(url)
  return response
}

const putCurrentCity = async(cityDetails,userId) => {
  const payload = {
    locale : {
      location : cityDetails
    }
  }
  const url = `${config.apiServerDomain}/users/${userId}`
  const response = await AxiosInstance.put(url,payload)
  return response
}

const multipleFileDownload = async(payload)=>{
  const url = `${config.apiServerDomain}/files/zip`
  const response = await AxiosInstance.post(url,payload)
  return response
}

const acceptInvite = async(payload) =>{
  const url = `${config.apiServerDomain}/invitations/${payload}/accept`
  const response = await axios.post(url)
  return response
}

const updateProfilePic = async(formData, token) => {
  const url = `${config.apiServerDomain}/users/avatar_url/content?access_token=${token}`
  const response = await axios.post(url, formData, {headers: {
    'Content-Type': 'multipart/form-data',
  }})
  return response
}

const putProfileInfo = async (payload,id) => {
const url = `${config.apiServerDomain}/users/${id}`
const response =  await AxiosInstance.put(url,payload)
return response 
}

const getfileLocationPathApi = async(fileId) => {
  const url = `${config.apiServerDomain}/files/${fileId}/get-path`
  const response = await AxiosInstance.get(url)
  return response
}

const getPortalWorkspaces = async(portalId) => {
  const url = `${config.apiServerDomain}/portals/${portalId}/workspaces`
  const response = await AxiosInstance.get(url)
  return response
}

const checkFavFolderCache = async (arr, portalId) =>{
const url = `${config.apiServerDomain}/portals/${portalId}/validate-favorite-smartfolder`
const response = await AxiosInstance.post(url,arr)
return response
}


const checkPermissionSearch = async(fileId) => {
  const url = `${config.apiServerDomain}/files/${fileId}/has-none-permission`
  const response = await AxiosInstance.get(url)
  return response
}

const getFavFolders =  async(portalId,type) => {
  const url = `${config.apiServerDomain}/portals/${portalId}/favorites?type=${type}`
  const response = await AxiosInstance.get(url)
  return response
}

const postFavFolder = async(portalId,favFolders,type) =>{
  const url = `${config.apiServerDomain}/portals/${portalId}/favorites?type=${type}`
  const response = await AxiosInstance.post(url,favFolders)
  return response
}

const deleteFavs = async(portalId,favs) => {
  const config1 = {
    data: favs // Data to be sent in the request body
  }
  const url = `${config.apiServerDomain}/portals/${portalId}/favorites`
  const response = await AxiosInstance.delete(url,config1)
  return response
}


export const Commonservice = {
    getProtal,
    getProfileInfo,
    getPortalUserEndpoint,
    getPortalPermissionEndpoint,
    getPortalAllowedClientsEndpoint,
    getAllWorkspacesListEndpoint,
    getWorkspace,
    getWorkspaceRootFolderChildren,
    getWorkspacePermissionsEndpoint,
    getWorkspaceUsersEndpoint,
    getWorkspaceTrashChildrenEndpoint,
    getUsersEndpoint,
    getPermissionsEndpoint,
    getAllFilesEndpoint,
    getUploadFileEndpoint,
    getUploadNewVersionEndpoint,
    getUploadChunkedInitEndpoint,
    getUploadChunksEndpoint,
    getDownloadFileEndpoint,
    getDownloadSharedLinkEndpoint,
    getSharedResourceEndpoint,
    getFilePermissionsEndpoint,
    getFileVersionsEndpoint,
    getFileVersionsUpdateEndpoint,
    getFileCopyEndpoint,
    getFileTrashEndpoint,
    getSharedLinkEndpoint,
    getSharedLinkUrlEndpoint,
    getSharedLinkEmailNotificationEndpoint,
    getSharedLinkMultiEmailNotificationEndpoint,
    getFileFolderEmailOptionsEndpoint,
    getCreateFolderEndpoint,
    getFolderEndpoint,
    getFolderChildrenEndpoint,
    getFolderCopyEndpoint,
    getFolderPermissionsEndpoint,
    getFolderTrashEndpoint,
    getSmartFolderEndpoint,
    getCreateSmartFolderEndpoint,
    getSmartFolderChildrenEndpoint,
    getSmartFolderPermissionsEndpoint,
    getSmartFolderTrashEndpoint,
    getPortalMetafieldEndpoint,
    getMetafieldEndpoint,
    getMetafieldValueEndpoint,
    getResourceMetafieldValueEndpoint,
    getMetafieldCriterionEndpoint,
    getSmartFolderMetafieldCriteriaEndpoint,
    getInviteUserUrl,
    getInvitationsEndpoint,
    getAcceptInvitationEndpoint,
    getResendInvitationEndpoint,
    getConfirmEmailEndpoint,
    getFolderInboundEmail,
    getWorkspaceInboundEmail,
    getPortalGroupsEndpoint,
    getWorkspaceGroupsEndpoint,
    getGroupsEndpoint,
    getCreateMembershipEndpoint,
    getMembershipsEndpoint,
    getMembershipsForGroup,
    getMembershipsForUser,
    getGroupsForUser,
    getWorkspaceGroupMembershipsForUser,
    getWorkspaceGroupsForUser,
    getEventsEndpoint,
    getSearchEndpoint,
    getResetPasswordEndpoint,
    getHistoryEndpoint,
    getFDVEntriesEndpoint,
    getFDVLicenseEndpoint,
    getFDVExportEndpoint,
    getCopyWorkspaceEndpoint,
    getFileFolderPathEndpoint,
    getUpdateWorkspaceEndpoint,
    getZipDownloadInitiateEndpoint,
    getZipDownloadEndpoint,
    getFileInfoEndpoint,
    getDomainAndPortalUrl,
    getCreateNewWorkspaceEndpoint,
    loadWeatherInfo,
    getPortalUsersEndpoint,
    getAllWorkspacesListEndpointFolder,
    putSingleAllowedClient,
    getFolderAndFilesChildrenEndpoint,
    getFolderDetail,
    getFileDetail,
    deletePortalMember,
    loadWeatherMap,
    updateProfilePhoto,
    addWorkSpace,
    getWorkspaceRootSmartFolderChildren,
    fetchLabel,
    getAllPortalUsers,
    putWorkSpaceCopy,
    deleteWorkSpace,
    putNameWorkSpace,
    sendDefaultSync,
    deleteInvitation,
    changeWorkspacePermission,
    deleteUserInWorkspace,
    postLabel,
    sendUserWorkSpace,
    putLabel,
    deleteLabel,
    deleteGroup,
    sentAddGroup,
    fetchGroupMembers,
    putAllowedClientsMultipleUsers,
    getSearchFile,
    getWorkspaceMembers,
    postDeleteGroupMember,
    sentAddGrpMember,
    updateDragnDropFile,
    renameGroup,
    postInvite,
    clearRecyclebin,
    createSmartFolderName,
    createSmartFolderMetaData,
    restoreFromRecyclebin,
    deleteRecycleBinData,
    deleteRecycleBinItems,
    createNewFileFolder,
    sentEmail,
    configureSmartFolder,
    deleteSmartFolderItem,
    updateLinkAccess,
    sentFolderDownload,
    getSmartFolderMetaData,
    sentGetVersions,
    postFileComment,
    sentFilePreview,
    sentViewId,
    drivePasteOperation,
    postFolderPermission,
    putUserPermission,
    putFolderCut,
    putFileCut,
    deleteMetafromSmartFolder,
    postDriveSendLink,
    postFilePermission,
    postDriveLabelDataUrl,
    putDriveLabelDataUrl,
    deleteDriveLabelDataUrl,
    postFileSubscription,
    postFileSync,
    deleteFileFolderFromDrive,
    renameFileFolderInDrive,
    postFileLock,
    deleteSmartFolderUrl,
    defaultMemberinWorkspace,
    updateDropFileProgress,
    downloadFile,
    configureNameSmartFolder,
    getLoggedUserPortalPermission,
    getLoggedUserWorkspacePermission,
    highestFolderFilePrem,
    putCurrentCity,
    multipleFileDownload,
    getFolder,
    acceptInvite,
    highestFileFolderPremContent,
    updateProfilePic,
    uploadChunkedEndApi,
    restrictedUploadFile,
    putProfileInfo,
    getfileLocationPathApi,
    getPortalWorkspaces,
    checkFavFolderCache,
    checkPermissionSearch,
    getFavFolders,
    postFavFolder,
    deleteFavs
  };


