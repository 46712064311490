import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topbarBg: 'primary',
  rebaseFavFolder: {},
  filesToUpload: {
    fil: [],
    dir: [],
    comment: '',
  },
  ogDirUpload: [],
  dropFlagToggle: false,
  searchPermission: {},
  getSearchPerm: {},
  errorAlert: {},
  minimize: false,
  uploadFailedItems: [],
  favFolderGet: [],
  sendFavFolder: {},
  deleteFavsData: {},
  driveCheckoutCookieState: false,
  uploadFailedItemsServerSide: [],
  rebaseSmartFavFolderData: [],
  favSmartFolderGet: [],
  sendFavSmartFolder: {},
  getItemPath: {},
  itemPath: {},
  acceptInvitationisLoading: false,
  homeMemberLoadingState: true,
};
export const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    getCommonSliceData: (state) => state.payload.customizer.selectedportal,
    ChangeTopbarColor: (state, action) => {
      state.topbarBg = action.payload;
    },
    updateFilesAndDirectoriesToUpload: (state, action) => {
      state.filesToUpload = action.payload;
    },
    updateogDirUpload: (state, action) => {
      state.ogDirUpload = action.payload;
    },
    updateDropFlagToggle: (state, action) => {
      state.dropFlagToggle = action.payload;
    },
    updateSearchPermission: (state, action) => {
      state.searchPermission = action.payload;
    },
    updateGetSearchPerm: (state, action) => {
      state.getSearchPerm = action.payload;
    },
    updateCommonAlert: (state, action) => {
      state.errorAlert = action.payload;
    },
    updateMinimize: (state, action) => {
      state.minimize = action.payload;
    },
    updateUploadFailedItems: (state, action) => {
      state.uploadFailedItems.push(action.payload);
    },
    clearUploadFailedItems: (state) => {
      state.uploadFailedItems = [];
    },
    clearIndividualFailedItems: (state, action) => {
      const { index } = action.payload;
      state.uploadFailedItems.slice(index, 1);
    },
    updateFavFolderGet: (state, action) => {
      state.favFolderGet = action.payload;
    },
    updateSendFavFolders: (state, action) => {
      state.sendFavFolder = action.payload;
    },
    updateDeleteFavs: (state, action) => {
      state.deleteFavsData = action.payload;
    },
    updatedriveCheckoutCookieState: (state, action) => {
      state.driveCheckoutCookieState = action.payload;
    },
    rebasedSmartFolder: (state, action) => {
      state.rebaseSmartFavFolderData = action.payload;
    },
    updateFavSmartFolderGet: (state, action) => {
      state.favSmartFolderGet = action.payload;
    },
    updateSendFavSmartFolders: (state, action) => {
      state.sendFavSmartFolder = action.payload;
    },
    updateGetItemPath: (state, action) => {
      state.getItemPath = action.payload;
    },
    updateItemPath: (state, action) => {
      state.itemPath = action.payload;
    },
    updateAcceptInvitationisLoading: (state, action) => {
      state.acceptInvitationisLoading = action.payload;
    },
    updateHomeMemberLoadingState: (state, action) => {
      state.homeMemberLoadingState = action.payload;
    },
  },
});

export const {
  ChangeTopbarColor,
  updateSearchPermission,
  updateGetSearchPerm,
  updateCommonAlert,
  updateDropFlagToggle,
  updateFilesAndDirectoriesToUpload,
  updateMinimize,
  updateUploadFailedItems,
  clearUploadFailedItems,
  clearIndividualFailedItems,
  updateFavFolderGet,
  updateSendFavFolders,
  updateDeleteFavs,
  updatedriveCheckoutCookieState,
  rebasedSmartFolder,
  updateFavSmartFolderGet,
  updateSendFavSmartFolders,
  updateGetItemPath,
  updateItemPath,
  updateAcceptInvitationisLoading,
  updateHomeMemberLoadingState,
} = CommonSlice.actions;

export default CommonSlice.reducer;
